.react-tel-input .flag-dropdown{
   top: 0;
 }
 .image-list-item{
   height: 40px;
   background-color: lightgrey;
   display: flex;
   justify-content: center;
   align-items: center;
   border-bottom: 1px solid black;
 }
 .rte .ant-form-item-control, .text-area textarea, .text-area .ant-form-item-control{
   width: 100%;
   max-width: 100%;
 }
 .text-area textarea {
   height: 100px;
   padding: 10px;
 }
 .offering-tab {
   background-color: lightgrey;
   border: 1px solid black;
   vertical-align: middle;
   display: flex!important;
   justify-content: center;
   align-items: center;
 }
 .offering-tab:hover {
  cursor: pointer;
}
.offering-tab .selected{
  background-color: lightblue;
  font-weight: 700;
}
.offering-tab p{
  margin: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.sun-editor .se-toolbar {
  z-index:1;
}
textarea:disabled { 
  background-color: #F5F5F5;
}
.property-details {
  text-align: 'center';
  color: darkblue; 
  font-size: 18px;
  margin: 40px 0 30px 0;
  font-weight: bold;
}