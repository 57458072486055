.member-details-wrapper {
  margin: 10px;
  background-color: white;
}
.member-details {
  margin: 10px 0;
  padding: 30px 20px 50px 20px;
}
.member-details h2 {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}
.member-table {
  width: auto;
  min-width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.member-table thead tr th,
.member-table tbody tr td {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  padding: 16px;
}
.member-table thead tr th {
  font-weight: 500;
  font-size: 16px;
  background-color: lightgrey;
}
/* .member-table tbody{
   min-height: 20px;
   display: block;
} */
.member-info {
  padding: 30px 20px 50px 20px;
}
.member-type {
  display: flex;
}
input[type="radio"].radio-button {
  margin-left: 50px;
  margin-right: 5px;
  margin-top: 6px;
}
input[type="radio"].radio-button:hover {
  cursor: pointer;
}
.radio-label {
  margin-left: 100px;
}
.member-box {
  border: 1px solid black;
}
.button-group {
  margin: 20px 0;
  padding-right: 20px;
  text-align: end;
}
button.ant-btn-link {
  border: 1px solid black;
  min-width: 80px;
  min-height: 40px;
  color: black;
  margin-right: 10px;
}
.ant-btn-link.add-button {
  color: white;
  background-color: royalblue;
}
.email-tag,
.phone-tag {
  display: flex;
  width: 100%;
}
.email-tag label,
.phone-tag label {
  width: 150px;
}
.email-tag input,
.phone-tag input {
  max-width: 300px;
  margin-top: -5px;
}
.react-tel-input .form-control {
  margin-top: -5px !important;
}
/* .react-tel-input .flag-dropdown{
   top: -5px;
} */
.react-tel-input {
  max-width: 300px;
}
.members-box {
  margin-top: 50px;
}
