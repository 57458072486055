@import "https://www.realcrowd.com/Assets/ClientBin/site-common.0b2a6b11.min.css";

#building {
    height: 32rem;
    /* margin-left: -340px; */
    width: 100%;
    position: relative;
    display: block;
}


#carddetails {
    position: relative;
    display: block;
    width: 100%;
    height: 6rem;
    /* left: 13px; */
    /* top: -430px; */
    background: #FFFFFF;
    box-shadow: 1px 15px 56px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: auto;
    top: -3rem;
}

#fieldsradius {
    position: relative;
    width: 95%;
    height: 3rem;
    /* left: 24px; */
    /* top: 18px; */
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 30px;
}

#incon1 {
    position: relative;
    top: 12px;
    /* left: 36px; */
    /* position: absolute; */
    /* width: 63px; */
    /* height: 21px; */
    /* left: 728px; */
    /* top: 267px; */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 21px; */
    color: #9B9B9B;
}


.disbox1{
    width: 8rem;
    position: relative;
    height: 31px;
    top: -41px;
    left: 8rem;
}

#mline {
    position: relative;
    width: 28px;
    height: 0px;
    top: -20px;
    left: 4.5rem;
    margin: auto;
    border: 1px solid #BDBDBD;
    transform: rotate( 
90deg
 );
}


#incon2 {
    position: relative;
    display: block;
    top: 12px;
    left: 33px;
    /* position: absolute; */
    /* width: 63px; */
    /* height: 21px; */
    /* left: 728px; */
    /* top: 267px; */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 21px; */
    color: #9B9B9B;
}

.disbox2{
    width: 8rem;
    position: relative;
    height: 31px;
    top: -42px;
    left: 8rem;
}

#mline1 {
    position: relative;
    display: block;
    width: 22px;
    height: 0px;
    top: -56px;
    left: 10rem;
    margin: auto;
    border: 1px solid #BDBDBD;
    transform: rotate( 
90deg
 );
}


#incon3 {
    position: relative;
    display: block;
    top: 12px;
    left: 33px;
    /* position: absolute; */
    /* width: 63px; */
    /* height: 21px; */
    /* left: 728px; */
    /* top: 267px; */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 21px; */
    color: #9B9B9B;
}


.disbox3{
    width: 8rem;
    position: relative;
    height: 31px;
    top: -42px;
    left: 15rem;
}

#buttoncssss {
    position: relative;
    display: block;
    width: 8rem;
    height: 48px;
    left: 9rem;
    /* top: -1px; */
    background: #0A87A8;
    border: 1px solid #149EC2;
    box-sizing: border-box;
    border-radius: 0px 30px 30px 0px;
}

#textcss {
    /* width: 57px; */
    /* height: 21px; */
    /* left: 1151px; */
    /* top: 267px; */
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}


#backblur{
    position: relative;
        display: block;
        padding: 7px -33px 16px;
        height: 516px;
        left: 0px;
        top: 0rem;
        background: linear-gradient( 
    180deg
     , rgba(225, 225, 224, 0) 0%, #C9C5C5 58%);
    }
    
    
    #mcontent1{
    position: relative;
        display: block;
        height: 53px;
        width: 100%;
        /* left: 700px; */
        top: 3rem;
        margin: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 52px;
        text-align: center;
        color: #050B37;
    }
    
    #downcolor{
    position: relative;
        display: block;
        width: 8rem;
        height: 24px;
        left: -11rem;
        top: -1.5rem;
        margin: auto;
        background: linear-gradient( 
    185.83deg
     , #94E9B7 -23.64%, #2D9759 102.89%);
        opacity: 0.6;
    
    }
    
    #mcontent2{
    position: relative;
        display: block;
        width: 60rem;
        height: 70px;
        /* left: 470px; */
        top: 7rem;
        margin: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #3F3F3F;
    }
    
    #mgroup{
        position: relative;
        display: block;
        width: 100%;
        height: 340px;
        /* left: 354px; */
        top: -13rem;
    }
    
    #mimage1{
        position: relative;
        display: block;
        width: 100%;
        height: 21rem;
        left: 0rem;
        top: 0rem;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 6px;
    }
    
    #mcontent3{
        position: relative;
        display: block;
        width: 100%;
        height: 24px;
        left: 0.5rem;
        top: -5rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
    
    #mcontent4{
        position: relative;
        display: block;
        width: 100%;
        /* height: 42px; */
        left: 9px;
        top: -6.5rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF; 
    }
    
    #mcontent5{
        position: relative;
        width: 100%;
        height: 53px;
        /* left: 737px; */
        top: -5rem;
        margin: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 52px;
        text-align: center;
        color: #050B37;
    }
    
    #mcolor4{
        position: relative;
        width: 8rem;
        height: 24px;
        left: -8rem;
        top: -2rem;
        margin: auto;
        background: linear-gradient( 
    180deg
     , #FCC82B -13.36%, #F8921F 105.18%);
        opacity: 0.8;
    }







    #box{
        position: relative;
        height: auto;
        display: block;
        width: auto;
        top: 0rem;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 15px 0px;
    }
    
    #box1{
        position: relative;
        height: auto;
        display: block;
        width: auto;
        top: 0rem;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 15px 0px;
    }
    
    #box2{
        position: relative;
        height: auto;
        display: block;
        width: auto;
        top: 0rem;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 15px 0px;
    }
    
    #frame0, #frame1, #frame2{
        position: relative;
        display: block;
        width: 365px;
        min-height: 653px;
        height: auto;
        left: 0px;
        top: 1px;
        background: #FFFFFF;
        box-shadow: 6px 10px 36px rgb(0 0 0 / 18%);
        border-radius: 6px;
        padding: 0 5px;
    }
    
    #frame1{
        left: 1rem;
    }
    #frame2{
        left: 2rem;
    }
    
    #mimage2{
        position: relative;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 4.15%;
        background: linear-gradient(
    180deg
    , rgba(4, 10, 54, 0) 32.67%, #040A36 120%);
    
    }
    
    #iiicon1{
        position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    left: 18rem;
    top: -3.5rem;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    }
    
    #iiicon2{
        position: relative;
        /* display: block; */
        left: 3.78%;
        /* right: 105.84%; */
        /* top: -4%; */
        /* bottom: 30%; */
        background: #FFFFFF;
    }
    
    #mcontent6{
        position: relative;
    display: block;
    width: 57px;
    height: -6px;
    left: -77px;
    top: -20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    }
    
    #iiiborder{
        position: relative;
        display: block;
        height: 35px;
        left: -22rem;
        top: 11px;
        /* bottom: -27px; */
        background: #74E79F;
        border-radius: 13px;
    }
    
    #iiisale{
        position: relative;
        width: 100px;
        height: 18px;
        left: calc(50% - 100px/2 + 0.5px);
        top: calc(30% - 7px/2);
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #050B37;
    }
    
    #heartcss{
        position: relative;
        display: block;
        left: 19rem;
        /* right: 11.17%; */
        /* top: 2.25%; */
        bottom: 35.33%;
        /* background: #FFFFFF; */
        color: white;
    }
    
    #contentbox{
        position: relative;
        display: block;
        height: auto;
        left: 5px;
    }
    
    #mcontent7{
        position: relative;
    width: 345px;
    height: 48px;
    left: 7px;
    top: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #18364A;
        
    }
    
    #mcontent8{
        position: relative;
        width: 100%;
        min-height: 40px;
        height: auto;
        left: 7px;
        top: -10px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #505762;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    
    #tablebox1{
        position: relative;
    display: block;
    width: 328px;
    height: 132px;
    left: 11px;
    top: 10px;
        
    }
    
    #tablebox{
        position: relative;
        display: block;
        width: 326px;
        height: 21px;
        left: -2px;
        
    }
    
    #content9{
        position: relative;
        display: block;
        width: 189px;
        height: 21px;
        left: 7px;
        /* top: 23px; */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2F343A;
        
    
    }
    
    #percentage1{
        position: relative;
        display: block;
        width: 21px;
        height: 21px;
        left: 292px;
        top: -3rem;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #505762;
    }
    
    #mline3{
        position: relative;
        display: block;
        width: 328px;
        height: 0px;
        left: 2px;
        top: -57px;
        opacity: 0.23;
        border: 1px solid #505762;
    }
    
    #tablebox2{
        position: relative;
        display: block;
        width: 326px;
        height: 21px;
        left: 0px;
        top: 36px;
        
    }
    
    #content10{
        position: relative;
        width: 189px;
        height: 21px;
        left: -21px;
        /* top: 388px; */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2F343A;
    
    }
    
    #percentage2{
        position: relative;
        width: 90px;
        height: 21px;
        left: 225px;
        top: -47px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #505762;
    }
    
    #mline4{
        position: relative;
    display: block;
    width: 328px;
    height: 0px;
    left: 2px;
    top: -66px;
    opacity: 0.23;
    border: 1px solid #505762;
    }
    
    #tablebox3{
        position: relative;
        display: block;
        width: 326px;
        height: 21px;
        left: -75px;
        top: 61px;
        
    }
    
    #content11{
        position: relative;
        width: 189px;
        height: 21px;
        left: 7px;
        /* top: 388px; */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2F343A;
        
    
    }
    
    #percentage3{
        position: relative;
        width: 39px;
        height: 21px;
        left: 350px;
        top: -46px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #505762;
    }
    
    #mline5{
        position: relative;
        display: block;
        width: 328px;
        height: 0px;
        left: 78px;
        top: -67px;
        opacity: 0.23;
        border: 1px solid #505762;
    }
    
    #tablebox4{
        position: relative;
        display: block;
        width: 326px;
        height: 21px;
        top: 80px;
        left: -43px;
    }
    
    #content12{
        position: relative;
        width: 189px;
        height: 21px;
        left: 7px;
        /* top: 388px; */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #2F343A;
        
    
    }
    
    #percentage4{
        position: relative;
        width: 39px;
        height: 21px;
        left: 320px;
        top: -47px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #505762;
    }
    
    #CTAS{
        position: relative;
        display: block;
        width: 365px;
        height: 55px;
        left: -5px;
        top: 56px;
    }
    #ctabox{
        position: relative;
        display: block;
        width: 365px;
        height: 54px;
        left: 0px;
        top: 1px;
        background: #F2F2F2;
    }
    
    #ctabutton{
        position: relative;
        display: block;
        width: 140px;
        /* height: 21px; */
        left: 25px;
        top: 12px;
        border: 0px solid #F2F2F2;
        background: #F2F2F2;
    }
    
    #ctatext{
        position: relative;
        left: -6%;
        /* right: -9.81%; */
        top: 15%;
        bottom: -31.25%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #18364A;
    
    }
    
    #ctabox1{
        position: relative;
        display: block;
        width: 190px;
        height: 54px;
        left: 176px;
        top: -53px;
        background: #0A87A8;
    }
    
    #ctabutton1{
        position: relative;
        display: block;
        width: 115px;
        height: 29px;
        left: 37px;
        top: 17px;
        border: 0px solid #0A87A8;
        background: #0A87A8;
    }
    
    #ctatext1{
        position: relative;
        display: block;
        /* left: 8.94%; */
        /* right: 16.26%; */
        /* top: 0%; */
        /* bottom: -31.25%; */
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
    
    }
    
    #loadmorecss{
        position: absolute;
    left: 8.33%;
    right: 4.17%;
    top: 12.5%;
    bottom: 12.5%;
    
    /* CBW/Small Body */
    
    background: #505762;
    }
    
    #loadmoretext{
    
        position: relative;
        display: block;
        left: 46%;
        /* right: -10.58%; */
        top: 6%;
        /* bottom: -38.46%; */
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #18364A;
    }
    
    #spinnercss{
        position: relative;
        /* width: 100%; */
        display: block;
        /* margin: auto; */
        left: 42.33%;
        /* right: 4.17%; */
        top: 10.5%;
        /* /* bottom: 13.5% */
    }
    

.lastbox{
    position: relative;
    display: block;
    width: 100%;
    height: 25rem;
}

/* #contentbox * {
    color: black;
} */
.tablebox{
    padding: 0 20px;
}

.tablebox .box-wrapper {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid black;
    background-color: white;
}
.tablebox .box-wrapper .label {
    float: left;
}
.tablebox .box-wrapper .value {
    float: right;
    color: black;
}

.heading-wrapper {
    margin-top: 50px;
    display: inline-block;
    margin-bottom: 30px;
}

.heading-wrapper .header {
    color: #203E61;
    font-weight: 700;
    float: left;
    width: auto;
}
.heading-wrapper .heading-line {
    width: 64%;
    margin-left: 50px;
    position: relative;
    margin-top: 2.5%;
    height: 0.5px;
    background-color: #203E61;
    float: right;
}

.disclaimer {
    padding: 0 40px;
}

.property-wrapper {
    padding: 40px 15px;
    height: 785px;
    border-right: 0.5px solid lightgray;
    border-bottom: 0.5px solid lightgray;
}

.property-wrapper:hover {
    cursor: pointer;
}

.property-wrapper * {
    color: #203E61;
}

.property-category {
    font-size: 14px;
    padding: 5px 10px;
    margin-left: 20px;
    color: #fff;
    background-color: #203E61;
    width: max-content;
    margin-bottom: 10px;
}

.property-title {
    font-weight: 500;
    margin-bottom: 5px;
}

.property-company {
    font-size: 14px;
    margin-bottom: 5px;
}
.details-box-wrapper {
    background-color: #e6e9ec;
    padding: 0 1em;
}
.details-box {
    display: flex;
    width: 100%;
    border-bottom: 0.5px solid darkgray;
    height: 38px;
    justify-content: space-between;
    align-items: center;
}
.details-box.last {
    border-bottom: none;
}
.details-box.outer {
    border-bottom: none;
}
.details-box .label {
    font-size: 14px;
    font-weight: 500;
}
.details-box .value {
    font-size: 16px;
    font-weight: 500;
}
.btn.invest-button {
    padding-left: 0;
}
.invest-button a {
    background-color: #4BBD5A;
    color: white;
    padding: 12px 20px;
}
.invest-button a i {
    color: white;
    font-weight: 500;
    font-size: 25px;
    top: 2px;
    position: relative;
}
.invest-button a span {
    color: white;
    margin-left: 10px;
    font-weight: 500;
}
.close-date {
    font-weight: 600;
}
.details-box-wrapper.talebox .target-wrapper {
    margin-top: -58px;
    z-index: 1;
    position: relative;
}
.top-target-wrapper {
    background-color: #e6e9ec;
    padding: 4px 8px;
    width: max-content;
}
.target-wrapper svg {
    margin-right: 2px;
    height: 11px;
}
.target-text {
    margin-right: 4px;
}
.property-city{
    margin-bottom: 5px;
    z-index: 1;
    color: white;
    font-weight: 500;
}
