/* Extra Large Devices, Wide Screens */
@media only screen and (max-width: 1600px) {
  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive {
    margin: "auto";
    width: "100%";
    font-size: 16px;
  }
  
  .table_responsive td {
    padding: .5rem;
  }

  .react-tel-input .form-control {
    width: 100%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive {
    margin: "auto";
    width: "100%";
    font-size: 16px;
  }
  
  .table_responsive td {
    padding: .5rem;
  }

  .react-tel-input .form-control {
    width: 100%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .video-js {
    width: 600px;
    height: 330px;
  }

  .tokens-area .tokens {
    margin: 0;
  }

  .tokens-area .tokens .token-body {
    padding: 50px 25px;
  }

  footer .footer-bottom {
    height: auto;
    line-height: 26px;
    padding: 50px 0;
    text-align: center;
  }
  footer .footer-bottom .foo-link {
    text-align: center;
    margin-top: 15px;
  }

  .logo-area {
    text-align: center;
  }

  header .menu-area {
    float: none;
    width: 100%;
    text-align: center;
  }

  #image1 {
    left: 0rem;
  }
  
  .homeimage {
    left: 4rem;
  }
  
  .col-md-6 {
    width: 100%;
  }

  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive {
    margin: "auto";
    width: "100%";
    font-size: 16px;
  }
  
  .table_responsive td {
    padding: .5rem;
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  .ant-tabs>.ant-tabs-nav,
  .ant-tabs>div>.ant-tabs-nav {
    overflow-x: scroll;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .slider {
    height: 300px;
  }
  .video-js {
    width: 480px;
    height: 249px;
  }

  .mobile-menu-area {
    display: block !important;
  }

  .my-video-dimensions {
    width: 480px;
    height: 249px;
  }

  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }

  .tokens-area .tokens {
    display: block;
    margin: 0 0 50px;
  }

  .tokens-area .tokens .token-body {
    display: block;
  }

  .tokens-area .token-con {
    margin: 0;
  }

  .e_chart_3 div {
    margin: 0 auto;
  }

  .token-details .cart-list .label-chatrs {
    padding: 62px 100px;
  }

  .token-details .owl-controls .owl-buttons div.owl-prev {
    left: 0;
    border-radius: 0 15px 15px 0;
  }
  .token-details .owl-controls .owl-buttons div.owl-next {
    right: 0;
    border-radius: 15px 0 0 15px;
  }

  .blog-area .owl-controls .owl-buttons {
    display: none;
  }

  header .mobilemenu .mean-container .mean-nav {
    margin-top: 38px;
  }

  .video-des .deslidt ul li .serials:before,
  .video-des .deslidt ul li .serials span.cre:before,
  .video-des .deslidt ul li .serials span.cre:after {
    display: none;
  }

  #clockdiv>div,
  #clockdiv2>div {
    margin-bottom: 20px;
  }

  .slider a.previousButton,
  .slider a.nextButton {
    display: none;
  }
  .easypiechart {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .team-area .team-list {
    height: auto;
  }

  .team2-area .team-list {
    height: auto;
  }

  .slider-area .carousel {
    padding: 30vh 0;
  }

  #image1 {
    left: 0rem;
  }

  .homeimage {
    left: 4rem;
  }

  .col-md-6{
    width: 100%;
  }

  .protab{
    margin-bottom: 180px;
  }

  .protext {
    left: 37%;
  }
  
  .protex1 {
    left: 37%;
  }

  .prosize1 {
    background-color: black;
  }
  
  .protext1 .nav-link {
    color: white;
  }

  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive {
    margin: "auto";
    width: "100%";
    font-size: 14px;
  }
  
  .table_responsive td {
    padding: .5rem;
  }

  .ant-form-item-label>label {
    height: 50px;
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  .offering-tab p {
    font-size: 12px;
  }

  .ant-tabs>.ant-tabs-nav,
  .ant-tabs>div>.ant-tabs-nav {
    overflow-x: scroll;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .video-js {
    width: 400px;
    height: 207px;
  }
  .my-video-dimensions {
    width: 400px;
    height: 207px;
  }

  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }

  .video-area .videos-heding h2 {
    font-size: 36px;
  }

  footer .footer-top .con ul li a {
    width: 65px;
    height: 65px;
    line-height: 59px;
    font-size: 30px;
  }

  footer .footer-top .con ul li {
    padding: 0 5px;
  }

  .media-area .mediabrand ul {
    margin: 0;
  }

  .e_chart_3 {
    height: 300px !important;
  }

  .token-details .owl-controls .owl-buttons div {
    display: none;
  }

  .media-area .mediabrand ul li {
    display: block;
    text-align: center;
    padding: 15px 0;
  }
  .team-area .team-list {
    margin-bottom: 50px !important;
    height: auto !important;
    text-align: center;
  }

  .team-area2 .team-list {
    overflow: hidden;
  }

  .team2-area .team-list {
    margin-bottom: 50px !important;
    height: auto !important;
    text-align: center;
  }

  .btc_timer_section_wrapper {
    padding-top: 60px !important;
  }

  .slider-area .slider-content ul li {
    padding: 0 2px;
  }

  .slider-area .slider-content ul li a {
    width: 40px;
    height: 40px;
    line-height: 34px;
  }

  footer .footer-top .con h2 {
    margin: 0 0 50px;
  }

  .token-details .cart-list .label-chatrs {
    padding: 50px;
  }

  .slider-area .carousel {
    padding: 30vh 0 25vh;
  }

  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .offering-tab p {
    font-size: 12px;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 375px) {
  .video-js {
    width: 320px;
    height: 182px;
  }

  .steps-area .steps-heading .right-con a {
    display: none;
  }

  .steps-area {
    text-align: center;
    padding: 40px 0;
  }

  .slider-area .carousel {
    padding: 30vh 0 20vh;
  }

  #carddetails {
    height: 362px;
  }

  #mline {
    display: none
  }

  #mline1 {
    display: none
  }

  .disbox1 {
    width: 10rem;
  }

  .disbox2 {
    width: 10rem;
    left: 8rem;
  }

  .disbox3 {
    width: 10rem;
    left: 8rem;
  }

  #buttoncssss {
    left: 6rem;
    border-radius: 30px;
  }

  #incon1 {
    left: 0px;
  }

  #incon2 {
    left: 0px;
  }

  #incon3 {
    left: 0px;
  }

  #ctabox1 {
    width: 182px;
    left: 169px;
  }

  #frame0,
  #frame1,
  #frame2 {
    max-width: 355px;
  }

  #propertyimage {
    width: 32rem;
    height: 23rem;
  }

  .protab {
    width: 100%;
    height: 60px;
    margin-bottom: 100px;
    background: #F5F5F5;
  }

  .protext {
    left: 100px;
    top: 9px;
  }

  .prosize1 {
    background-color: black;
  }

  .protext1 .nav-link {
    color: white;
  }

  .Frame {
    height: 200px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 345px;
  }

  .overviewvideo {
    width: 345px;
    left: 0px;
  }

  #oabouttext{
    width: 345px;
  }

  .keyfacts {
    width: 345px;
  }

  .textframe{
    width: 345px;
    margin-top: 50px;
  }

  .overviewframe {
    width: 345px;
  }

  .wd_scroll_wrap{
    overflow: hidden;
  }

  .investnow {
    margin-right: 0rem;
  }

  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive {
    margin: "auto";
    width: "100%";
    font-size: 12px;
  }
  
  .table_responsive td {
    padding: .5rem;
  }

  .ant-form-item-label>label {
    height: 50px;
  }

  .ant-steps {
    overflow-x: scroll;
  }

  .ant-steps-navigation .ant-steps-item-container {
    margin-left: 0px;
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  .offering-tab p {
    font-size: 12px;
  }

  .ant-tabs>.ant-tabs-nav,
  .ant-tabs>div>.ant-tabs-nav {
    overflow-x: scroll;
  }

  .ant-steps-navigation {
    overflow-x: scroll;
  }

  .ant-steps-navigation .ant-steps-item-container {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .video-js {
    width: 300px;
    height: 155px;
  }
  .my-video-dimensions {
    width: 300px;
    height: 155px;
  }

  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }

  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    width: 70px;
    height: 70px;
  }

  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button::before {
    height: 70px;
    line-height: 70px;
    width: 30px;
    background-size: contain;
  }

  .video-area .videos-heding h2 {
    font-size: 30px;
  }

  .tokens-area .tokens .token-body .left-btn {
    padding: 0 20px;
    font-size: 16px;
  }
  .tokens-area .tokens .token-body .right-btn {
    padding: 0 20px;
    font-size: 16px;
  }

  .token-details .cart-list .label-chatrs {
    padding: 30px 25px;
  }

  .team-area .team-list {
    height: auto;
    padding-bottom: 30px;
  }
  .team2-area .team-list {
    height: auto;
    padding-bottom: 30px;
  }

  .steps-area .steps-heading h2 {
    font-size: 24px;
  }

  .video-des .deslidt ul li .left-con .box-con .dbox,
  .video-des .deslidt ul li .right-con .box-con .dbox,
  .video-des .deslidt ul li .center-con .box-con .dbox {
    padding: 30px !important;
  }
}
@media (max-width: 1199px) {
  header .menu-area nav ul li a {
    font-size: 12px;
  }
  .btn1 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
  }
  .btn2 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
  }
  .btn3 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
  }

  header .menu-area {
    padding-top: 5px;
  }

  header .menu-area nav ul li {
    padding: 0 9px;
  }

  #clockdiv div>span,
  #clockdiv2 div>span {
    width: 100px;
    height: 189px;
    line-height: 182px;
  }

  .btc_timer_section_wrapper {
    padding-top: 90px;
  }

  .slider-area .slider-content h2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .logo-area {
    text-align: left;
  }

  .logo-area {
    width: 234px !important;
  }

  .steps-area .steps-heading .right-con span {
    display: none;
  }

  .tokens-area .tokens .token-body {
    height: auto;
  }

  .media-area .mediabrand ul {
    text-align: center;
  }
}
@media (max-width: 1199px) and (min-width: 991px) {
  .rete-list {
    width: 23% !important;
    margin-left: 15px !important;
  }
}

/* For Mobile With Large Screen */
@media only screen and (max-width: 425px) {
  #carddetails {
    height: 362px;
  }

  #mline {
    display: none
  }

  #mline1 {
    display: none
  }

  .disbox1 {
    width: 12rem;
  }

  .disbox2 {
    width: 12rem;
    left: 8rem;
  }

  .disbox3 {
    width: 12rem;
    left: 8rem;
  }

  #buttoncssss {
    left: 6rem;
    border-radius: 30px;
  }

  #incon1 {
    left: 0px;
  }

  #incon2 {
    left: 0px;
  }

  #incon3 {
    left: 0px;
  }

  #ctabox1 {
    width: 182px;
    left: 169px;
  }

  #frame0,
  #frame1,
  #frame2 {
    max-width: 364px;
    margin: 20px;
  }

  #propertyimage {
    width: 32rem;
    height: 23rem;
  }

  .protab {
    width: 100%;
    height: 60px;
    margin-bottom: 100px;
    background: #F5F5F5;
  }

  .protext {
    left: 105px;
    top: 9px;
  }

  .prosize1 {
    background-color: black;
  }

  .protext1 .nav-link {
    color: white;
  }

  .Frame {
    height: 200px;
    width: 320px;
    overflow-x: scroll;
  }

  .overviewvideo {
    width: 345px;
    left: 0px;
  }

  #image1 {
    left: 5rem;
  }

  #oabouttext {
    width: 345px;
  }

  .keyfacts{
    width: 345px;
  }

  .textframe {
    width: 345px;
    margin-top: 50px;
  }

  .overviewframe{
    width: 345px;
  }

  .wd_scroll_wrap{
    overflow: hidden;
  }

  .ant-descriptions-item-container {
    margin-top: 3rem;
    display: block;
  }

  .table_responsive{
    margin: "auto";
    width: "100%";
    font-size: 12px;
  }

    .table_responsive td {
      padding: .5rem;
    }

    .ant-form-item-label>label {
      height: 50px;
    }

    .ant-steps {
      overflow-x: scroll;
    }

    .ant-steps-navigation .ant-steps-item-container {
      margin-left: 0px;
    }

    .react-tel-input .form-control {
      width: 100%;
    }

    .ant-tabs>.ant-tabs-nav,
    .ant-tabs>div>.ant-tabs-nav {
      overflow-x: scroll;
    }

    .ant-steps-navigation {
      overflow-x: scroll;
    }

    .ant-steps-navigation .ant-steps-item-container {
      margin-left: 0px;
    }
}
