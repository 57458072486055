.label{
    font-size: 18px;
    color:#8f7f7f;
}
.value{
    font-size: 16px;
    margin-bottom: 10px;
}
.llpBankInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #d3cbcb;
}