.wrapper {
  position: relative;
  margin: 5px;
}

.wrapper img {
  max-width: 100%;
  height: auto;
}

.wrapper iframe {
  display: none;
}

.play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.subscribe-button {
  position: absolute;
  z-index: 666;
  top: 10%;
  left: 15%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.play-btn:hover {
  cursor: pointer;
}

.play-btn:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}

.row::after {
  content: "";
  clear: both;
  display: block;
}

[class*="col-"] {
  float: left;
}

.header-new {
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}
.header .heading {
  font-size: 40px;
  color: #2c2e53;
  font-weight: bold;
  text-align: center;
}
.aside {
  padding: 15px;
  font-size: 15px;
  margin-left: 30px;
  margin-top: 40px;
}

.aside .social-link {
  display: flex;
}
.footer {
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-between;
  text-align: right;
  padding: 15px;
  margin-left: 50px;
  margin-right: 50px;
}

.my-radio-b {
  background: rgb(255, 255, 255);
  border-radius: 100%;
  border-width: 3px solid #33c2ff;
  opacity: 1;
  width: 60px;
  height: 60px;
  box-shadow: 0px 2px 8px 0px #0000000D;

}

.my-radio-b:hover {
  background: linear-gradient(180deg, #386FD0 0%, #2FBACA 100%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.my-radio-b:hover .logo {
  fill: rgb(255, 255, 255);
}

.footer .feature-group {
  width: 100%;
}

.under-head {
  font-size: xx-large;
  color: #012049;
  font-weight: bolder;
  font-family: monospace;
  text-align: left;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    margin-left: 0px;
    margin-left: 0px;
  }
  .under-head {
    text-align: center;
  }
}

#feature-button-side {
  display: none;
}
/* For desktop: */
.col-1 {
  width: 10.33%;
}
.col-2 {
  width: 16.66%;
  padding: 5px;
}
.col-2-3 {
  width: 20%;
  padding: 2px;
}

.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
  padding-left: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 5px;
}
.col-4-1 {
  width: 33.33%;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 0px;
}

.col-4-2 {
  width: 33.33%;
  padding: unset;
}

.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 48%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  #feature-button {
    display: none;
  }
  #feature-button-side {
    display: block;
    margin-right: 10px;
  }
  .aside {
    margin-right: 15px;
    font-size: 15px;
    text-align: center;
  }
}
.master {
  padding-top: 40px;
  background: #fff;
}

.video-player {
  border-radius: 15px;
  overflow: hidden;
}

.danger-btn {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  border-radius: 40px;
  margin-right: 10px;
  padding: 11px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: #fff;
  width: 126px;
  height: 39px;
}

.primary-btn {
  border: 2px solid #2fbaca;
  border-radius: 40px;
  margin-right: 10px;
  padding: 11px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  width: 126px;
  height: 39px;
}

.primary-btn:hover {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  color: #fff;
}
.media-img {
  width: 10rem;
  height: 6rem;
  background-color: rgb(255, 255, 255);
}

.associate-img {
  width: 10rem;
  height: 6rem;
  background-color: #f6f6ff;
  display: inline-block;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  display: none;
  width: 180px;
  height: max-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: -2%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}

.tooltip .tooltiptext .tooltp-button {
  background-color: rgb(78, 189, 189);
  border-radius: 3rem;
  color: #2c2e53;
  margin: 5px;
  padding: 5px;
  text-align: right;
}

.main-head {
  color: #2c2e53;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  padding: 15px;
}

.profiler {
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.profiler:hover {
  display: flex;
  flex-direction: column-reverse;
  background: #f6f6ff;
  border-radius: 20px;
  padding: 1rem;
  -ms-transform: scaleY(1.008); /* IE 9 */
  transform: scaleY(1.008);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.profiler .text-area {
  padding: 1rem;
  text-align: justify;
  color: #012049;
  height: 25rem;
}

.profiler .text-area-2 {
  padding: 1rem;
  text-align: justify;
  color: #012049;
  height: 17rem;
}

.profiler .image-area {
  width: 100%;
  height: 100%;
  text-align: center;
}

.c-img {
  margin: auto;
  border-radius: 15px;
  border: 3px solid #012049;
}
/* .profiler .overlay {
  background: #ebf3f5;
  border-radius: 24px;
  border: solid rgb(1, 255, 255);  
} */
.profiler .heading {
  color: #012049;
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 28px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.profiler .heading:before {
  width: 30px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 55%;
  margin-left: -35px;
  margin-bottom: 10px;
  background-color: #690d2a;
}

.profiler .heading:after {
  width: 200px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 5px;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 10px;
  background-color: #690d2a;
}
.wrapper {
  position: relative;
  margin: 5px;
}

.wrapper img {
  max-width: 100%;
  height: auto;
}

.wrapper iframe {
  display: none;
}

.play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.subscribe-button {
  position: absolute;
  z-index: 666;
  top: 10%;
  left: 15%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.play-btn:hover {
  cursor: pointer;
}

.play-btn:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}

.row::after {
  content: "";
  clear: both;
  display: block;
}

[class*="col-"] {
  float: left;
}

.header-new {
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}
.header .heading {
  font-size: 40px;
  color: #2c2e53;
  font-weight: bold;
  text-align: center;
}
.aside {
  padding: 15px;
  font-size: 15px;
  margin-left: 30px;
  margin-top: 40px;
}

.aside .social-link {
  display: flex;
}
.footer {
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-between;
  text-align: right;
  padding: 15px;
  margin-left: 50px;
  margin-right: 50px;
}

.my-radio-b {
  background: #fff;
  border-radius: 100%;
  border-width: 3px solid #33c2ff;
  opacity: 1;
  width: 60px;
  height: 60px;
  box-shadow: 0px 2px 8px 0px #0000000D;

}

.my-radio-b:hover {
  background: linear-gradient(180deg, #386FD0 0%, #2FBACA 100%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.my-radio-b:hover .logo {
  fill: rgb(255, 255, 255);
}

.footer .feature-group {
  width: 100%;
}

.under-head {
  font-size: xx-large;
  color: #012049;
  font-weight: bolder;
  font-family: monospace;
  text-align: left;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    margin-left: 0px;
    margin-left: 0px;
  }
  .under-head {
    text-align: center;
  }
}

#feature-button-side {
  display: none;
}
/* For desktop: */
.col-1 {
  width: 10.33%;
}
.col-2 {
  width: 16.66%;
  padding: 5px;
}
.col-2-3 {
  width: 20%;
  padding: 2px;
}

.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
  padding-left: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 5px;
}
.col-4-1 {
  width: 33.33%;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 0px;
}

.col-4-2 {
  width: 33.33%;
  padding: unset;
}

.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 48%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  #feature-button {
    display: none;
  }
  #feature-button-side {
    display: block;
    margin-right: 10px;
  }
  .aside {
    margin-right: 15px;
    font-size: 15px;
    text-align: center;
  }
}
.master {
  padding-top: 40px;
  background: #fff;
}

.video-player {
  border-radius: 0px;
  overflow: hidden;
}

.danger-btn {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  border-radius: 40px;
  margin-right: 10px;
  padding: 11px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: #fff;
  width: 126px;
  height: 39px;
}

.primary-btn {
  border: 2px solid #2fbaca;
  border-radius: 40px;
  margin-right: 10px;
  padding: 11px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em; 
  text-transform: uppercase;
  color: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  width: 126px;
  height: 39px;
}

.primary-btn:hover {
  background: linear-gradient(90deg, #386fd0 0%, #2fbaca 100%);
  color: #fff;
}
.media-img {
  width: 10rem;
  height: 6rem;
  background-color: rgb(255, 255, 255);
}

.associate-img {
  width: 10rem;
  height: 6rem;
  background-color: #f6f6ff;
  display: inline-block;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  display: none;
  width: 180px;
  height: max-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: -2%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}

.tooltip .tooltiptext .tooltp-button {
  background-color: rgb(78, 189, 189);
  border-radius: 3rem;
  color: #2c2e53;
  margin: 5px;
  padding: 5px;
  text-align: right;
}

.main-head {
  color: #2c2e53;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  padding: 15px;
}

.profiler {
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.profiler:hover {
  display: flex;
  flex-direction: column-reverse;
  background: #f6f6ff;
  border-radius: 20px;
  padding: 1rem;
  -ms-transform: scaleY(1.008); /* IE 9 */
  transform: scaleY(1.008);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.profiler .text-area {
  padding: 1rem;
  text-align: justify;
  color: #012049;
  height: 25rem;
}

.profiler .text-area-2 {
  padding: 1rem;
  text-align: justify;
  color: #012049;
  height: 17rem;
}

.profiler .image-area {
  width: 100%;
  height: 100%;
  text-align: center;
}

.c-img {
  margin: auto;
  border-radius: 15px;
  border: 3px solid #012049;
}
/* .profiler .overlay {
  background: #ebf3f5;
  border-radius: 24px;
  border: solid rgb(1, 255, 255);  
} */
.profiler .heading {
  color: #012049;
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 28px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.profiler .heading:before {
  width: 30px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 55%;
  margin-left: -35px;
  margin-bottom: 10px;
  background-color: #690d2a;
}

.profiler .heading:after {
  width: 200px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 5px;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 10px;
  background-color: #690d2a;
}

#img{
  width: 1845px;
  height: 752px;
  margin-top: -3px;

}

.img1{
  display: block;
  position: relative;
  width: 30rem;
  border-radius: 1rem;
}

.content{
  display: block;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 0px;
  left: 0px;
  top: -32rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 85px;
  line-height: 90px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.content1{
  display: block;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 0px;
  top: -26rem;
  left: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 85px;
  line-height: 90px;
  text-align: center;
  color: #6bdbfa;
  text-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.content3{

  display: block;
  justify-content: center;
  position: relative;
  /* width: 61%; */
  height: 0px;
  left: 0px;
  top: -17rem;
  padding: 0rem 30rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: #FFFFFF;


}

.content4{
  position: relative;
  width: max-content;
  height: 0px;
  left: 2rem;
  padding: 0px;
  top: 2rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  color: #050B37;
}

.content5{
  position: relative;
  width: 87%;
  /* height: 225px; */
  left: 2rem;
  top: 3rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #18364A;
  display: block;
  text-align: left;

}

.newcontentbox{
  display: block;
  position: relative;
  width: 5rem;
  height: 26px;
  left: 2rem;
  top: 25px;
  background: linear-gradient( 
180deg
, #FCC82B -13.36%, #F8921F 105.18%);
  opacity: 0.6;
}

.Image{
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 100%;
  height: 693px;
  left: 0px;
  top: 0px;
}

.rowrectangle{
  position: relative;
  /* width: auto; */
  height: 26rem;
  left: 0px;
  background: #F8F8F8;
}

.sizeeee{
  height: 1136px;
  width: 100%;
}


