a.nav-link {
    color: #3553a4 !important;
}

a.nav-link.active {
    color: #9acb4b !important;
}

.btn-demo {
    background-color: #3553a4 !important;
    color: #ffffff !important;
}

.btn-demo-outline {
    background-color: #ffffff !important;
    color: #3553a4 !important;
}

.raise_fund {
    font-size: 55px;
    color: #e2ff8f !important;
    font-weight: 900 !important;
    text-shadow: 2px 1px 1px #000000;
}

.next_project {
    font-size: 28px;
    color: #ffffff !important;
    font-weight: 500 !important;
    text-shadow: 2px 1px 1px #000000;
    line-height: 33px;
}

.tokenization {
    color: #3553a4 !important;
}

.tokenization_content {
    color: #000000 !important;
    line-height: 33px !important;
}

.tokenization_benefits {
    color: #3553a4 !important;
}

.text-light-blue {
    color: #149ec3 !important;
}

.card-border {
    border-bottom: 5px solid #9acb4b !important;
}

.features {
    list-style: none;
}

.features li::before {
    content: "\2022";
    color: #9acb4b;
    font-weight: 900;
    font-size: 18px;
    display: inline-block;
    width: 1em;
    margin-left: 5px !important;
}

.demo {
    background-color: #3553a4 !important;
}

.btn-outline {
    border: 1px solid #3b579d !important;
    border-radius: 5px;
    color: #000000;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.tokenize-place {
    color: #3553a4;
    margin-top: 6px;
}

.list-item {
    color: #3553a4;
    margin-top: 6px;
}

.tokenization_icon {
    padding: 15px;
    width: 100px;
    height: 100px;
    margin: auto;
}

.tokenize_image {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.banner_image {
    background-image: url('.././../assets/images/banner/30673489560_92fc86a00e_o.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.img_banner{
    max-width: 100%;
    height: 100%;
}