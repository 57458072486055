@import "https://www.realcrowd.com/Assets/ClientBin/site-common.0b2a6b11.min.css";

#propertyimage {
    /* width: 837px; */
    position: relative;
    display: block;
    width: 52rem;
    height: 38rem;
    left: 0px;
    top: 0px;
    background: #C4C4C4;
}

#propertydetails {
    position: relative;
    display: block;
    width: 365px;
    height: 7rem;
    /* left: 1131px; */
    top: 8rem;
    /* overflow-y: scroll; */
}

#investbox {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    position: relative;
    width: 360px;
    height: 90px;
    /* left: 918px; */
    top: -25px;
    background: #FFFFFF;
    box-shadow: 5px 7px 21px rgb(0 0 0 / 22%);
    border-radius: 6px;
}

#buttonrect {
    position: relative;
    display: block;
    height: 47px;
    width: auto;
    /* left: 25px; */
    /* top: 11px; */
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin: 10px 0px; */
}

#investback {
    position: relative;
    display: block;
    height: 3rem;
    /* left: 2.5px; */
    /* right: 0%; */
    /* top: 10.47%; */
    /* bottom: -3.42%; */
    background: #149EC3;
    border: 2px solid #149EC3;
    box-sizing: border-box;
    border-radius: 40px;
    width: 19rem;
}

#investtext {
    position: relative;
    display: block;
    /* height: 21px; */
    /* left: 34.84%; */
    /* right: 34.52%; */
    /* top: calc(50% - 21px/2 + 0.07px); */
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

#frame {
    position: relative;
    display: block;
    width: 365px;
    height: 800px;
    /* left: 1132px; */
    top: 9rem;
}

#framerect {
    overflow-x: hidden;
    position: relative;
    display: block;
    width: 365px;
    height: 800px;
    left: 0px;
    top: -1px;
    background: #F8F8F8;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    margin-bottom: 180px;
}
.overview-frame{
    height: 2900px;
}
.onlypropertyframe{
    position: relative;
    width: 839px;
    height: auto;
    left: 0px;
    top: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0px;
  
  }

#offerrect {
    position: relative;
    display: block;
    width: 365px;
    height: 54px;
    left: 0px;
    top: 0px;
    background: #E0E0E0;
}

#offertext {
    position: relative;
    display: block;
    /* height: 43px; */
    /* width: 100%; */
    /* left: 24px; */
    top: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #333333;
}

#textformat {
    position: relative;
    display: block;
    width: 166px;
    height: 669px;
    left: 19px;
    top: 2rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    /* text-align: right; */
    color: #828282;
}

#textnumbers {
    position: relative;
    display: block;
    width: 133px;
    height: 555px;
    left: 13rem;
    top: -39.7rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3F3F3F;
}

/* got from static css */
.gm-control-active>img {
    box-sizing: content-box;
    display: none;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%)
}

.gm-control-active>img:nth-child(1) {
    display: block
}

.gm-control-active:hover>img:nth-child(1),
.gm-control-active:active>img:nth-child(1) {
    display: none
}

.gm-control-active:hover>img:nth-child(2),
.gm-control-active:active>img:nth-child(3) {
    display: block
}
.gm-ui-hover-effect {
    opacity: .6
}

.gm-ui-hover-effect:hover {
    opacity: 1
}
.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span,
.gm-style .gm-style-mtc div {
    font-size: 10px;
    box-sizing: border-box
}
@media print {
    .gm-style .gmnoprint,
    .gmnoprint {
        display: none
    }
}

@media screen {

    .gm-style .gmnoscreen,
    .gmnoscreen {
        display: none
    }
}
.gm-style-pbc {
    transition: opacity ease-in-out;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center
}

.gm-style-pbt {
    font-size: 22px;
    color: white;
    font-family: Roboto, Arial, sans-serif;
    position: relative;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}
.gm-style img {
    max-width: none;
}

.gm-style {
    font: 400 11px Roboto, Arial, sans-serif;
    text-decoration: none;
}

/* custom updated style */
.container-fluid * {
    color: #203E61;
}
.invest * {
    float: left;
    margin-right: 20px;
    color: #203E61;
    margin-top: 10px;
}
.invest h4 {
    float: right;
}
.invest h6 {
    margin-top: 15px;
}
.invest button {
    color: #fff;
    background-color: #4bbd5a;
    border-color: #4bbd5a;
    padding: 10px 50px;
    margin-top: 0px;
}

.detail-container {
    background-color: #efefef;
    padding: 10px;
    padding-bottom: 0;
}

.detail-container h5 {
    text-align: center;
}

.detail-line {
    min-height: 35px;
    height: auto;
    border-bottom: 1px solid #B6BEC5;
    margin-top: 10px;
}
/* .detail-line.back-color {
    background-color: white;
} */
.detail-line .line-right {
    float: right;
    font-size: 16px;
    font-weight: 500;
}

.image-detail .image-container, .image-detail .detail-container {
    height: max-content;
}

.ant-tabs-tab-btn {
    font-size: 18px;
    font-weight: 500;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 20px;
}
.member-table th {
    width: 30%;
}
.member-table td {
    width: 70%;
}

.row-striped {
    display: inline-block;
    width: 100%;
    min-height: 36px;
    height: auto;
    padding: 10px;
    background-color: #EFEFEF;
}
.row-striped:nth-child(even) { 
    background-color: white;
}
.row-striped .left-sec{
    float: left;
    font-size: 14px;
    font-weight: 500;
}
.row-striped .right-sec{
    float: right;
    font-size: 16px;
}